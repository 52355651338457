import { useSearchParams } from "@remix-run/react";
import { skipToken, useQuery, useQueryClient } from "@tanstack/react-query";

import { fetchUser } from "~/routes/login/fetcher";

import { User } from "~/services/api-schemas";

const CURRENT_USER_KEY = ["currentUserLocale"];
export function useAppLocale({ userFetchEnabled }: { userFetchEnabled: boolean }) {
  // Refetch on hook mount after 10 minutes of stale data.
  // data will always be cached as there will always be a `useConfiguration` hook mounted.
  const { data } = useQuery({
    queryKey: CURRENT_USER_KEY,
    queryFn: userFetchEnabled ? fetchUser : skipToken,
    staleTime: 10 * 10,
  });

  const [searchParams] = useSearchParams();

  // // i.e. "es_419", "zh_CN", there cannot be multiple dashes like "zh-Hant-CN" which is supported in the browser.
  // // see https://github.com/svenfuchs/rails-i18n?tab=readme-ov-file#available-locales-1 for supported locales in rails.
  // // although docs show a dash it comes back as "_" from API
  const locale = userFetchEnabled ? data?.locale?.replace("_", "-") : searchParams.get("locale");
  const lang = locale?.split("-")[0];
  // const country = data?.country ?? "BE";
  const country = "BE";

  if (locale && locale.indexOf("-") !== -1 && isSupportedLocale(locale)) return locale;
  // continue with base language if full locale is not supported i.e "es", "zh"
  if (lang && country && isSupportedLocale(`${lang}-${country}`)) return `${lang}-${country}`;
  if (lang && isSupportedLocale(lang)) return lang;

  return "en-GB";
}

export function useUpdateUserLocale() {
  const queryClient = useQueryClient();

  return (locale: string) => {
    queryClient.setQueryData<User>(CURRENT_USER_KEY, (user) => {
      if (user) return { ...user, locale };
    });
  };
}

function isSupportedLocale(locale: string) {
  // "supportedLocalesOf" will exit with an error if a non-existing language tag is passed
  try {
    const supportsNumberFormat = !!Intl.NumberFormat.supportedLocalesOf(locale).length;
    const supportsDateTimeFormat = !!Intl.DateTimeFormat.supportedLocalesOf(locale).length;

    return supportsNumberFormat && supportsDateTimeFormat;
  } catch (error) {
    if (import.meta.env.DEV) console.warn(error);

    return false;
  }
}
